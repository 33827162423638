import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { readPosts } from '../data/db';
import '../styles/BlogPostDetail.css';


function isImageLink(link) {
    return link.match(/\.(jpeg|jpg|gif|png|webp)$/) != null;
}

function BlogDetail() {
    const { id } = useParams();
    const post = readPosts().find(post => post.id === parseInt(id));

    useEffect(() => {
        if (post) {
            document.title = post.title;
        }
    }, [post]);

    if (!post) {
        return  <div className="not-found-container">
         <img src="https://cdn-icons-png.flaticon.com/512/2748/2748583.png" alt="Not Found GIF" />
         <h2>Post não encontrado</h2>
         <p>Desculpe, o post que você está procurando não foi encontrado.</p>
       </div>
    }

    const { title, author, date, content, description } = post;

    return (
        <div className="blog-detail">
            <h1>{title}</h1>
            <p><i>{description}</i></p>
            <p><b>Por:</b> {author}</p>
            <p><b>Data de publicação:</b> {date}</p>
            <div className="post-content">
                {content.split('\n').map((line, index) => {
                    if (line.startsWith('http')) {
                        if (isImageLink(line)) {
                            return <img key={index} src={line} alt={title} />;
                        } else {
                            return (
                                <a key={index} href={line} target="_blank" rel="noopener noreferrer">
                                    {line}
                                </a>
                            );
                        }
                    } else {
                        return <p key={index}>{line}</p>;
                    }
                })}
            </div>
        </div>
    );
}

export default BlogDetail;