import React, { useEffect } from 'react';
import '../styles/TermsDetail.css';

function TermsDetail() {

  useEffect(() => {
    document.title = `Termos de Uso - ConnectifyTech`;
  }, []);

  return (
    <div className="terms-detail">
      <h1>Terms of Use</h1>

      <h2>Introduction</h2>
      <p>
        Welcome to ConnectifyTech. By accessing or using our apps and websites, you agree to comply with and be bound by the following terms of use. Please read these terms carefully before using our services.
      </p>

      <h2>Free Apps and Websites</h2>
      <p>
        ConnectifyTech offers various free apps and websites. These services are provided to you at no cost, and you are not permitted to resell, redistribute, or use them for any illegal or unauthorized purposes. Unauthorized use includes, but is not limited to, using the services to infringe on intellectual property rights or for activities that violate any laws or regulations.
      </p>

      <h2>Freemium and Paid Apps</h2>
      <p>
        In addition to free services, ConnectifyTech offers freemium and paid apps. Freemium apps provide basic features for free, with additional premium features available for a fee. Paid apps require a purchase to access. All purchases are final and non-refundable, except as required by law. Users are not allowed to redistribute, resell, or share these apps in a manner that violates these terms or applicable laws.
      </p>

      <h2>Custom Solutions for Third Parties</h2>
      <p>
        ConnectifyTech also develops apps and websites for third parties. These custom solutions are subject to separate agreements and terms as defined in the specific contracts with those third parties.
      </p>

      <h2>Ownership and Restrictions</h2>
      <p>
        All apps, websites, and related services provided by ConnectifyTech are the intellectual property of ConnectifyTech. You may not copy, modify, distribute, sell, or lease any part of our services or included software, nor may you reverse engineer or attempt to extract the source code of that software, unless laws prohibit these restrictions or you have our written permission.
      </p>

      <h2>Use of Services</h2>
      <p>
        You agree to use our services only for lawful purposes and in a way that does not infringe the rights of, restrict, or inhibit anyone else's use and enjoyment of the services. Prohibited behavior includes harassing or causing distress or inconvenience to any other user, transmitting obscene or offensive content, or disrupting the normal flow of dialogue within our services.
      </p>

      <h2>Limitation of Liability</h2>
      <p>
        ConnectifyTech provides the apps and websites "as is" and "as available." We do not warrant that the services will be uninterrupted or error-free. ConnectifyTech disclaims all warranties, express or implied, including but not limited to implied warranties of merchantability and fitness for a particular purpose.
      </p>

      <h2>Changes to These Terms</h2>
      <p>
        ConnectifyTech reserves the right to update these terms of use at any time. Any changes will be posted on this page, and we will notify you of significant changes. Your continued use of our services after any changes indicates your acceptance of the new terms.
      </p>

      <h2>Contact Us</h2>
      <p>
        If you have any questions about these terms of use, please contact us at support@connectifytech.com.
      </p>

      <p>
        By using our services, you acknowledge that you have read, understood, and agree to be bound by these terms of use. Thank you for choosing ConnectifyTech.
      </p>
    </div>
  );
}

export default TermsDetail;
