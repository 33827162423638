import React, { useEffect, useState } from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { readMembers, readInfo } from '../data/db';
import '../styles/AboutPage.css';

function AboutPage() {
  useEffect(() => {
    document.title = "Sobre Nós";
  }, []);

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };


  const copyToClipboard = (text) => {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(text)
        .then(() => {
          alert('Número copiado para a área de transferência: ' + text);
        })
        .catch((error) => {
          console.error('Erro ao copiar: ', error);
          promptCopy(text);
        });
    } else {
      promptCopy(text);
    }
  };

  const promptCopy = (text) => {
    prompt('Copie o número para seu app de chamadas:', text);
  };

  const [companyInfo, setCompanyInfo] = useState({});

  useEffect(() => {
    setCompanyInfo(readInfo());
  }, []);

  return (
    <div className="aboutpage">
      <h2>Sobre</h2>
      <p>
        Na ConnectifyTech buscamos fornecer soluções tecnológicas para nossos clientes, ao mesmo tempo que tudo seja acessível
        tanto para tem administra, quanto para quem usa.
      </p>

      <h3>Transformando ideias em realidades</h3>
      <p>
        Nosso objetivo é ir além de simplesmente desenvolver produtos tecnológicos. Buscamos entender profundamente as necessidades de 
        cada cliente para criar soluções personalizadas que impactem positivamente seus negócios. Desde a concepção até a implementação, 
        trabalhamos lado a lado com você para transformar suas ideias em realidade. Nossa abordagem é colaborativa e flexível, permitindo
        que você seja parte integral do processo de desenvolvimento.
      </p>

      <h3>Excelência em tudo o que fazemos</h3>
      <p>
        A excelência é o nosso padrão. Estamos sempre em busca de aprimorar nossas habilidades, adotar as últimas tendências 
        e oferecer um serviço excepcional. Acreditamos que a tecnologia tem o poder de simplificar processos, aumentar a produtividade 
        e promover a inovação. É com esse espírito que nos dedicamos a cada projeto, sempre buscando melhorar e inovar.
      </p>

      <h3>Parceiros no seu sucesso</h3>
      <p>
        Quando você se conecta com a ConnectifyTech, você encontra um parceiro comprometido em impulsionar seu negócio para o sucesso. 
        Nossa equipe é composta por profissionais apoiados e motivados, sempre prontos para ajudar e apoiar. Juntos, vamos navegar pelos 
        desafios e aproveitar as oportunidades que a tecnologia oferece. Estamos aqui para ajudá-lo a alcançar seus objetivos e transformar
        seu mundo digital.
      </p>

      <p>
        Conecte-se conosco e descubra como a ConnectifyTech pode impactar positivamente sua jornada tecnológica. Estamos ansiosos para colaborar 
        com você e criar soluções que façam a diferença.
      </p>





      <h2>Conheça Nossa Equipe</h2>
      <Carousel responsive={responsive}>
        {readMembers().map((member) => (
          <div key={member.id} className="team-member">
            <a href={`/about/${member.id}`}>
              <img src={member.image} alt={member.name} />
              <h3>{member.name}</h3>
            </a>
            <p>{member.position}</p>
            <p>{member.degree}</p>
          </div>
        ))}
      </Carousel>

      {/*<h2>Endereço</h2>
      <p>{companyInfo.address}</p>*/}

      <h2>Contate-nos</h2>
      <p>Email: <a href={`mailto:` + companyInfo.email}>{companyInfo.email}</a></p>
      <p>WhatsApp: <a href={`https://wa.me/` + companyInfo.whatsapp} target="_blank" rel="noopener noreferrer">Enviar mensagem</a></p>
      <p>
        Telefone: <span style={{ cursor: 'pointer', textDecoration: 'underline' }} onClick={() => copyToClipboard(companyInfo.phone)}>
          {companyInfo.phone}
        </span>
      </p>
    </div>
  );
}

export default AboutPage;