import React from 'react';
import '../styles/Description.css';
const Description = () => {
  return (
    <div className="home-description">
      <div className="home-description-left">
        <img src="https://cdn.icon-icons.com/icons2/1738/PNG/512/iconfinder-technologymachineelectronicdevice23-4026437_113360.png" alt="image-alt" />
      </div>
      <div className="home-description-right">
        <h2>Aqui as idéias se tornam realidade!</h2>
        <p>A ConnectifyTech é onde a tecnologia se encontra com a criatividade e a inovação, transformando idéias em realidade.</p> 
        <p>Se você está procurando por uma solução que faça a diferença, está no lugar certo!</p> 
      </div>
    </div>
  );
};

export default Description;