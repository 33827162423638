import React, { useEffect } from 'react';
import Banner from '../components/Banner';
import Description from '../components/Description';
import Portfolio from '../components/Portfolio';
import Contact from '../components/Contact'
import '../styles/HomePage.css';

function HomePage() {

  useEffect(() => {
    document.title = "ConnectifyTech"
  }, []);

  return (
    <div className="homepage">
      <Banner />
      <Description/>
      <Portfolio/>
      <Contact/>
    </div>
  );
}

export default HomePage;
