import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import '../styles/PrivacyDetail.css';

function PrivacyDetail() {
  const { app } = useParams();
  const decodedApp = decodeURIComponent(app || "ConnectifyTech Projects");

  useEffect(() => {
    document.title = `${decodedApp} - Privacy`;
  }, [decodedApp]);

  return (
    <div className="privacy-detail">
      <h1>Privacy Policy for {decodedApp}</h1>
      <p>
        This policy is valid for projects, such as apps and websites, developed by ConnectifyTech.
      </p>
      <h2>Terms & Conditions</h2>
      <p>
        By downloading or using {decodedApp}, these terms will automatically apply to you – you should make sure therefore that you read them carefully before using the app or website. You’re not allowed to copy or modify the app or website, any part of the app or website, or our trademarks in any way. You’re not allowed to attempt to extract the source code of the app or website, and you also shouldn’t try to translate the app or website into other languages or make derivative versions. The app or website itself, and all the trademarks, copyright, database rights, and other intellectual property rights related to it, still belong to ConnectifyTech.
      </p>
      <p>
        ConnectifyTech is committed to ensuring that the app or website is as useful and efficient as possible. For that reason, we reserve the right to make changes to the app or website or to charge for its services, at any time and for any reason. We will never charge you for the app or website or its services without making it very clear to you exactly what you’re paying for.
      </p>
      <p>
        The {decodedApp} stores and processes personal data that you have provided to us, to provide our Service. It’s your responsibility to keep your phone and access to the app or website secure. We therefore recommend that you do not jailbreak or root your phone, which is the process of removing software restrictions and limitations imposed by the official operating system of your device. It could make your phone vulnerable to malware/viruses/malicious programs, compromise your phone’s security features and it could mean that the {decodedApp} won’t work properly or at all.
      </p>
      <p>
        The app or website may use third-party services that state their Terms and Conditions.
      </p>
      <ul>
        <li><a href="https://policies.google.com/terms" target="_blank" rel="noopener noreferrer">Google Play Services</a></li>
        <li><a href="https://firebase.google.com/terms/analytics" target="_blank" rel="noopener noreferrer">Google Analytics for Firebase</a></li>
        <li><a href="https://www.appodeal.com/home/terms-of-service/" target="_blank" rel="noopener noreferrer">Appodeal</a></li>
      </ul>
      <h2>Privacy Policy</h2>
      <p>
      ConnectifyTech may have developed {decodedApp} as a free, premium, fremium or even private app. See the description of the specific app or website for more details.
      </p>
      <p>
        This page is used to inform visitors regarding our policies with the collection, use, and disclosure of Personal Information if anyone decides to use our Service.
      </p>
      <p>
        If you choose to use our Service, then you agree to the collection and use of information in relation to this policy. The Personal Information that we collect is used for providing and improving the Service. We will not use or share your information with anyone except as described in this Privacy Policy.
      </p>
      <p>
        The terms used in this Privacy Policy have the same meanings as in our Terms and Conditions, which are accessible at {decodedApp}, unless otherwise defined in this Privacy Policy.
      </p>
      <h2>Information Collection and Use</h2>
      <p>
        For a better experience, while using our Service, we may require you to provide us with certain personally identifiable information, including but not limited to name, email, and location. The information that we request will be retained on your device and is not collected by us in any way.
      </p>
      <p>
        The app or website may use third-party services that may collect information used to identify you.
      </p>
      <ul>
        <li><a href="https://www.google.com/policies/privacy/" target="_blank" rel="noopener noreferrer">Google Play Services</a></li>
        <li><a href="https://firebase.google.com/policies/analytics" target="_blank" rel="noopener noreferrer">Google Analytics for Firebase</a></li>
        <li><a href="https://www.appodeal.com/home/privacy-policy/" target="_blank" rel="noopener noreferrer">Appodeal</a></li>
      </ul>
      <h2>Log Data</h2>
      <p>
        We want to inform you that whenever you use our Service, in a case of an error in the app or website we collect data and information (through third-party products) on your phone called Log Data. This Log Data may include information such as your device Internet Protocol (“IP”) address, device name, operating system version, the configuration of the app or website when utilizing our Service, the time and date of your use of the Service, and other statistics.
      </p>
      <h2>Cookies</h2>
      <p>
        Cookies are files with a small amount of data that are commonly used as anonymous unique identifiers. These are sent to your browser from the websites that you visit and are stored on your device's internal memory.
      </p>
      <p>
        This Service does not use these “cookies” explicitly. However, the app or website may use third-party code and libraries that use “cookies” to collect information and improve their services. You have the option to either accept or refuse these cookies and know when a cookie is being sent to your device. If you choose to refuse our cookies, you may not be able to use some portions of this Service.
      </p>
      <h2>Service Providers</h2>
      <p>
        We may employ third-party companies and individuals due to the following reasons:
      </p>
      <ul>
        <li>To facilitate our Service;</li>
        <li>To provide the Service on our behalf;</li>
        <li>To perform Service-related services; or</li>
        <li>To assist us in analyzing how our Service is used.</li>
      </ul>
      <p>
        We want to inform users of this Service that these third parties have access to their Personal Information. The reason is to perform the tasks assigned to them on our behalf. However, they are obligated not to disclose or use the information for any other purpose.
      </p>
      <h2>Security</h2>
      <p>
        We value your trust in providing us your Personal Information, thus we are striving to use commercially acceptable means of protecting it. But remember that no method of transmission over the internet, or method of electronic storage is 100% secure and reliable, and we cannot guarantee its absolute security.
      </p>
      <h2>Links to Other Sites</h2>
      <p>
        This Service may contain links to other websites. If you click on a third-party link, you will be directed to that site. Note: If the external sites do not contain the domain "onehostapps.com" or "connectifytech.com", then most likely this site is not operated by us. Therefore, we strongly recommend that you review the Privacy Policy of these sites. We have no control and assume no responsibility for the content, privacy policies or practices of any third-party websites or Services.
      </p>
      <h2>Children’s Privacy</h2>
      <p>
        Our services have no age restriction, however, as they are a powerful tool and require certain technical knowledge, use by minors is not recommended. Therefore, parents, we do not recommend that your children under the age of 13 use this tool, and if you are using it without consent and you have any questions, we are ready to help you.
      </p>
      <h2>Changes to This Privacy Policy</h2>
      <p>
        We may update our Privacy Policy from time to time. Thus, you are advised to review this page periodically for any changes. We will notify you of any changes by posting the new Privacy Policy on this page. Changes are effective immediately after they are posted on this page.
      </p>
      <h2>Contact Us</h2>
      <p>
        If you have any questions or suggestions about our Privacy Policy, do not hesitate to contact us at support@connectifytech.com.
      </p>
      <h2>Data Deletion</h2>
      <p>
        If you wish to delete your personal data from our services, please contact us at support@connectifytech.com with your request, and we will process your request in accordance with applicable laws and regulations.
      </p>
    </div>
  );
}

export default PrivacyDetail;
